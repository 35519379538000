const ResponseStatus = {
  PENDING: "PENDING",
  CONFIRMED: "CONFIRMED",
  EXPIRED: "EXPIRED",
  FUTURE: "FUTURE",
  WAITING_PAYMENT: "WAITING_PAYMENT",
  WAITING_PARTIAL_PAYMENT: "WAITING_PARTIAL_PAYMENT",
  WAITING_PAYMENT_PIX: "WAITING_PAYMENT_PIX",
  WAITING_PAYMENT_BANK_SLIP: "WAITING_PAYMENT_BANK_SLIP",
  WAITING_PAYMENT_BOLEPIX: "WAITING_PAYMENT_BOLEPIX",
  PRE_AUTHORIZATION_ERROR: "PRE_AUTHORIZATION_ERROR",
  PRE_AUTHORIZATION_WAITING: "PRE_AUTHORIZATION_WAITING",
  CANCELLED_BY_GATEWAY: "CANCELED_BY_GATEWAY",
};

export default ResponseStatus;
