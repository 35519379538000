import { useEffect, useRef } from "react";

interface RefreshOptions {
  interval?: number; // in milliseconds
  onRefresh: () => void;
}

const useRefresh = ({
  interval = 15000, // default 15 seconds
  onRefresh,
}: RefreshOptions) => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    // Skip the first render to avoid immediate toast
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // Set up interval for subsequent refreshes
    const intervalId = setInterval(onRefresh, interval);

    return () => clearInterval(intervalId);
  }, [interval, onRefresh]);
};

export default useRefresh;
