import ResponseStatus from "./api";
import ApiPaymentMethods from "./apiPaymentMethods";
import RoutesPath from "./RoutesPath";

export const waitingPaymentRoutes = {
  [ResponseStatus.WAITING_PAYMENT_BANK_SLIP]: RoutesPath.BANK_SLIP,
  [ResponseStatus.WAITING_PAYMENT_PIX]: RoutesPath.PIX,
  [ResponseStatus.WAITING_PAYMENT_BOLEPIX]: RoutesPath.BOLEPIX,
  [ResponseStatus.PRE_AUTHORIZATION_WAITING]:
    RoutesPath.PRE_AUTHORIZATION_WAITING,
  [ResponseStatus.EXPIRED]: RoutesPath.EXPIRED_PAYMENT,
};

export const confirmedPaymentRoutes = {
  [ApiPaymentMethods.PIX]: RoutesPath.PIX_SUCCESS,
  [ApiPaymentMethods.CREDIT_CARD]: RoutesPath.CREDIT_CARD_SUCCESS,
  [ApiPaymentMethods.BANK_SLIP]: RoutesPath.BANK_SLIP_SUCCESS,
};

export const errorPaymentRoutes = {
  [ApiPaymentMethods.CREDIT_CARD]: {
    [ResponseStatus.PRE_AUTHORIZATION_ERROR]: RoutesPath.CREDIT_CARD_ERROR,
    [ResponseStatus.CANCELLED_BY_GATEWAY]: RoutesPath.CREDIT_CARD_ERROR,
  },
  [ApiPaymentMethods.PIX]: {
    [ResponseStatus.PRE_AUTHORIZATION_ERROR]: RoutesPath.PIX_ERROR,
  },
};

export const errorRoutes = {
  [ResponseStatus.PRE_AUTHORIZATION_ERROR]: RoutesPath.PRE_AUTHORIZATION_ERROR,
};

export const scheduledPaymentRoutes = {
  [ResponseStatus.FUTURE]: RoutesPath.CREDIT_CARD_SCHEDULED,
};
