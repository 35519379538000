import React from "react";
import { ApiPaymentMethods, Strings } from "src/constants";
import { useLocation } from "react-router-dom";
import {
  Divider,
  PaymentMethodInfo,
  ProductInfo,
  SummaryHeader,
  TotalAmount,
} from "src/components";

type OrderSummaryCardProps = {
  order?: CheckoutType;
  paymentMethodName?: string;
  className?: string;
};

// Main component
const PaymentSummaryCard: React.FC<OrderSummaryCardProps> = ({
  order,
  paymentMethodName,
  className,
}) => {
  const pathname = useLocation().pathname;
  const isCreditCardRoute = pathname.includes("CreditCard");
  const totalAmount = order?.checkoutPreferences?.totalAmount ?? 0;
  const paymentMethod =
    paymentMethodName || order?.paymentInstruments?.[0]?.method;
  const checkoutpreferencesPaymentMethods =
    order?.checkoutPreferences?.paymentMethods?.[0]?.type ?? "";
  const installmentCount = order?.checkoutPreferences?.installmentCount;
  const installmentValue = order?.checkoutPreferences?.installmentValue;
  const isCrediCardMethod =
    order?.paymentInstruments?.[0]?.method === ApiPaymentMethods.CREDIT_CARD ||
    isCreditCardRoute;

  return (
    <div
      className={`inline-flex w-full flex-col items-center justify-center gap-8 rounded-lg border border-black/0 bg-color-neutral-soft p-8 shadow lg:w-[400px] ${className}`}
    >
      <SummaryHeader summaryText={Strings.SUMMARY} />
      <div className="flex h-auto flex-col items-center justify-center self-stretch">
        <ProductInfo
          productTitle={Strings.PRODUCT}
          description={order?.insurance?.type?.description}
        />
        {!isCreditCardRoute && (
          <>
            <Divider />
            <PaymentMethodInfo
              method={paymentMethod}
              amount={totalAmount ?? 0}
            />
          </>
        )}
        <Divider />
        <TotalAmount total={totalAmount} />
        {isCrediCardMethod && (
          <PaymentMethodInfo
            method={checkoutpreferencesPaymentMethods}
            amount={totalAmount ?? 0}
            installmentCount={installmentCount}
            installmentValue={installmentValue}
          />
        )}
      </div>
    </div>
  );
};

export default PaymentSummaryCard;
