const ApiPaymentMethods: Record<string, AllowedPaymentMethodsType> = {
  PIX: "PIX",
  CREDIT_CARD: "CREDIT_CARD",
  BANK_SLIP: "BANK_SLIP",
  OPEN_FINANCE: "OPEN_FINANCE",
  DIRECT_DEBIT: "DIRECT_DEBIT",
  BOLEPIX: "BOLEPIX",
};

export default ApiPaymentMethods;
