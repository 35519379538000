export const MainLayout = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <main
      className={`flex grow flex-col items-center bg-color-neutral-base-2 ${className}`}
    >
      {children}
    </main>
  );
};

export default MainLayout;
