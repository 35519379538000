import { createContext, useContext } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "src/components";
import ErrorCard from "src/components/shared/ErrorCard";
import { useCheckout } from "src/hooks";
import { mockData } from "src/utils/mockData";

const SharedDataContext = createContext<CheckoutType | undefined>(undefined);

export const useCheckoutDataContext = () => {
  const context = useContext(SharedDataContext);
  if (!context) {
    throw new Error("Error in loading the context");
  }
  return context;
};

export const SharedDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const params = useParams<OrderSubscriptionParamsType>();
  const token = params.token as string;

  const { data, isFetching, error } = useCheckout(token);
  const providerData = mockData(data, { isUseMockData: false });

  if (isFetching) return <Spinner />;
  if (error) return <ErrorCard errorMessage={error} />;

  return (
    <SharedDataContext.Provider value={providerData}>
      {children}
    </SharedDataContext.Provider>
  );
};
