import React, { Suspense, lazy } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Footer, Header, Spinner } from "./components";
import { SharedDataProvider } from "./providers/SharedDataProvider";
import * as Sentry from "@sentry/react";
import { TestHeader } from "./components/shared/TestHeader";
import { FallbackComponent } from "./components/shared/SentryFallback";
import CreditCardPaymentError from "./pages/CreditCardPaymentError";
import { RoutesPath } from "./constants";
import PreAuthorizationWaiting from "./pages/PreAuthorizationWaiting";
import PixPaymentError from "./pages/PixPaymentError";
import ExpiredPayment from "./pages/ExpiredPayment";

const Pix = lazy(() => import("./pages/Pix"));
const Order = lazy(() => import("./pages/Order"));
const BankSlip = lazy(() => import("./pages/BankSlip"));
const CreditCard = lazy(() => import("./pages/CreditCard"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const PaymentMethods = lazy(() => import("./pages/PaymentMethods"));
const OpenFinance = lazy(() => import("./pages/OpenFinance"));
const OpenFinanceReview = lazy(() => import("./pages/OpenFinanceReview"));
const PaymentSuccess = lazy(() => import("./pages/PaymentSuccess"));
const PaymentTimeout = lazy(() => import("./pages/PaymentTimeout"));
const OpenFinanceAuthorization = lazy(
  () => import("./pages/OpenFinanceAuthorization"),
);
const PreAuthorizationError = lazy(
  () => import("./pages/PreAuthorizationError"),
);
const BankSlipWaitingPayment = lazy(
  () => import("./pages/BankSlipWaitingPayment"),
);
const PaymentScheduledSuccess = lazy(
  () => import("./pages/PaymentScheduledSuccess"),
);
const PaymentConfirmedPage = lazy(() => import("./pages/PaymentConfirmed"));

const DirectDebit = lazy(() => import("./pages/DirectDebit"));
const DirectDebitAuthorization = lazy(
  () => import("./pages/DirectDebitAuthorization"),
);
const DirectDebitPayementSuccess = lazy(
  () => import("./pages/DirectDebitPayementSuccess"),
);
const Bolepix = lazy(() => import("./pages/Bolepix"));
const BolepixSuccess = lazy(() => import("./pages/BolepixSuccess"));
const BolepixError = lazy(() => import("./pages/BolepixError"));

const queryClient = new QueryClient();

const AppLayout: React.FC = () => {
  return (
    <main
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <TestHeader />
      <Header />
      <Routes>
        <Route path="" element={<Order />} />
        <Route path={RoutesPath.PAYMENT_METHODS} element={<PaymentMethods />} />
        <Route path={RoutesPath.PIX} element={<Pix />} />
        <Route path={RoutesPath.PIX_SUCCESS} element={<PaymentSuccess />} />
        <Route path={RoutesPath.PIX_ERROR} element={<PixPaymentError />} />
        <Route path={RoutesPath.BANK_SLIP} element={<BankSlip />} />
        <Route
          path={RoutesPath.BANK_SLIP_SUCCESS}
          element={<PaymentSuccess />}
        />
        <Route
          path={RoutesPath.BANK_SLIP_WAITING_PAYMENT}
          element={<BankSlipWaitingPayment />}
        />
        <Route path={RoutesPath.CREDIT_CARD} element={<CreditCard />} />
        <Route
          path={RoutesPath.CREDIT_CARD_SUCCESS}
          element={<PaymentSuccess />}
        />
        <Route
          path={RoutesPath.CREDIT_CARD_ERROR}
          element={<CreditCardPaymentError />}
        />
        <Route
          path={RoutesPath.PRE_AUTHORIZATION_WAITING}
          element={<PreAuthorizationWaiting />}
        />
        <Route
          path={RoutesPath.CREDIT_CARD_SCHEDULED}
          element={<PaymentScheduledSuccess />}
        />
        <Route path={RoutesPath.OPEN_FINANCE} element={<OpenFinance />} />
        <Route
          path={RoutesPath.OPEN_FINANCE_REVIEW}
          element={<OpenFinanceReview />}
        />
        <Route
          path={RoutesPath.OPEN_FINANCE_AUTHORIZATION}
          element={<OpenFinanceAuthorization />}
        />
        <Route
          path={RoutesPath.OPEN_FINANCE_SUCCESS}
          element={<PaymentSuccess />}
        />
        <Route
          path={RoutesPath.OPEN_FINANCE_TIMEOUT}
          element={<PaymentTimeout />}
        />
        <Route path={RoutesPath.EXPIRED_PAYMENT} element={<ExpiredPayment />} />
        <Route
          path={RoutesPath.PRE_AUTHORIZATION_ERROR}
          element={<PreAuthorizationError />}
        />
        <Route
          path={RoutesPath.PAYMENT_CONFIRMED}
          element={<PaymentConfirmedPage />}
        />
        <Route path={RoutesPath.DIRECT_DEBIT} element={<DirectDebit />} />
        <Route
          path={RoutesPath.DIRECT_DEBIT_AUTHORIZATION}
          element={<DirectDebitAuthorization />}
        />
        <Route
          path={RoutesPath.DIRECT_DEBIT_SUCCESS}
          element={<DirectDebitPayementSuccess />}
        />
        <Route
          path={RoutesPath.PAYMENT_SCHEDULED_SUCCESS}
          element={<PaymentScheduledSuccess />}
        />
        <Route path={RoutesPath.BOLEPIX} element={<Bolepix />} />
        <Route path={RoutesPath.BOLEPIX_SUCCESS} element={<BolepixSuccess />} />
        <Route path={RoutesPath.BOLEPIX_ERROR} element={<BolepixError />} />
      </Routes>
      <Footer />
    </main>
  );
};

const App: React.FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={<FallbackComponent />}>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<Spinner />}>
          <ToastContainer autoClose={8000} />
          <BrowserRouter>
            <Routes>
              <Route
                path=":token/*"
                element={
                  <SharedDataProvider>
                    <AppLayout />
                  </SharedDataProvider>
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
