import React from "react";
import { Icons } from "src/constants";
import { CheckoutError } from "src/types/error";

const ErrorCard: React.FC<{ errorMessage: CheckoutError }> = ({
  errorMessage,
}) => {
  return (
    <main className="flex h-screen w-full flex-col items-center justify-between py-10">
      <div className="flex flex-col items-center justify-center px-4 text-center">
        <h1 className="mb-8 text-6xl font-bold text-lime-500 md:text-7xl">
          Oops!
        </h1>

        <div className="max-w-md space-y-4">
          <h2 className="text-xl font-semibold">
            Hmmm, não encontramos seu pedido.
          </h2>

          <p className="text-gray-600">
            Parece que esse pedido expirou ou não existe.
          </p>

          <p className="text-gray-600">Por favor, solicite um novo pedido.</p>
        </div>
      </div>
      <section className="flex flex-col items-center space-y-4">
        <h1 className="text-3xl font-bold">{errorMessage.statusCode}</h1>
        <h3 className="text-lg">
          {errorMessage?.cause || "Erro desconhecido"}
        </h3>
        <div className="text-center">
          <p> Request Id:</p>
          <p>{errorMessage.requestId}</p>
        </div>
      </section>
      <img src={Icons.Pay2b} alt="Pay2b" />
    </main>
  );
};

export default ErrorCard;
