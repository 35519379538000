import { useLocation } from "react-router-dom";
import { RoutesPath } from "src/constants";
import {
  Bank,
  BarcodeIcon,
  Check,
  Close,
  CreditCardIcon,
  Flag,
  OpenFinance,
  PixIcon,
  Edit,
  Timer,
} from "src/constants/Icons";
import { useCheckoutDataContext } from "src/providers/SharedDataProvider";

const Divider: React.FC = () => (
  <div className="inline-flex w-full items-center gap-2 self-stretch py-2">
    <div className="h-[0px] min-w-14 shrink grow basis-0 border border-color-neutral-base-2 bg-color-neutral-base-2"></div>
  </div>
);
const IconBackground: React.FC<{
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}> = ({ children, className, style }) => (
  <div
    className={`flex aspect-square size-8 items-center justify-center rounded-full bg-color-neutral-base-2 ${className}`}
    style={style}
  >
    {children}
  </div>
);
const LayoutCardStep: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <section className="flex justify-center rounded-lg border border-black/0 bg-color-neutral-soft object-center p-6 shadow md:min-w-[612px]">
    <div className="flex min-w-32 justify-center gap-2">{children}</div>
  </section>
);

const CheckedStep: React.FC = () => {
  const order = useCheckoutDataContext();
  const backgroundColor =
    order?.visualPreferences?.layout?.colors?.buttonConfirmBackground;
  return (
    <IconBackground style={{ backgroundColor }}>
      <Check className="size-5" />
    </IconBackground>
  );
};

const CurrentBankSlipStep: React.FC = () => (
  <IconBackground className="bg-color-neutral-base-5">
    <BarcodeIcon className="size-5" style={{ color: "white" }} />
  </IconBackground>
);
const CurrentPixStep: React.FC = () => (
  <IconBackground className="bg-color-neutral-base-5">
    <PixIcon className="size-5" style={{ color: "white" }} />
  </IconBackground>
);
const CurrentCreditCardStep: React.FC = () => (
  <IconBackground className="bg-color-neutral-base-5">
    <CreditCardIcon className="size-5 text-color-neutral-soft" />
  </IconBackground>
);

const CurrentBankStep: React.FC = () => (
  <IconBackground className="bg-color-neutral-base-5">
    <Bank className="size-5 text-color-neutral-soft" />
  </IconBackground>
);
const NextOpenFinanceStep: React.FC = () => (
  <IconBackground>
    <OpenFinance className="h-full w-full text-[#C1C5CA]" />
  </IconBackground>
);
const NextEditStep: React.FC = () => (
  <IconBackground>
    <Edit className="text-[#C1C5CA]" />
  </IconBackground>
);
const CurrentEditStep: React.FC = () => (
  <IconBackground className="bg-color-neutral-base-5">
    <Edit className="size-5 text-color-neutral-soft" />
  </IconBackground>
);
const CurrentOpenFinanceStep: React.FC = () => (
  <IconBackground className="bg-color-neutral-base-5">
    <OpenFinance className="h-full w-full text-color-neutral-soft" />
  </IconBackground>
);
const CurrentErrorStep: React.FC = () => (
  <IconBackground className="bg-color-neutral-base-5">
    <Close className="size-3 text-color-neutral-base-2" />
  </IconBackground>
);

const FlagStep: React.FC = () => (
  <IconBackground>
    <Flag className="size-5" />
  </IconBackground>
);
const TimerStep: React.FC = () => (
  <IconBackground className="bg-color-status-info-base">
    <Timer className="size-5 text-color-status-info-text" />
  </IconBackground>
);

const pathComponentMap = [
  {
    paths: [
      RoutesPath.PAYMENT_SCHEDULED_SUCCESS,
      RoutesPath.CREDIT_CARD_SCHEDULED,
    ],
    components: {
      step1: <CheckedStep />,
      step2: <TimerStep />,
    },
  },
  {
    paths: [
      RoutesPath.CREDIT_CARD_SUCCESS,
      RoutesPath.PIX_SUCCESS,
      RoutesPath.BANK_SLIP_SUCCESS,
      RoutesPath.OPEN_FINANCE_SUCCESS,
    ],
    components: {
      step1: <CheckedStep />,
      step2: <CheckedStep />,
    },
  },
  {
    paths: [RoutesPath.DIRECT_DEBIT_SUCCESS],
    components: {
      step1: <CheckedStep />,
      step2: <CheckedStep />,
      step3: <CheckedStep />,
    },
  },
  {
    paths: [
      RoutesPath.OPEN_FINANCE_TIMEOUT,
      RoutesPath.EXPIRED_PAYMENT,
      RoutesPath.PRE_AUTHORIZATION_ERROR,
    ],
    components: {
      step1: <CheckedStep />,
      step2: <CurrentErrorStep />,
    },
  },
  {
    paths: [
      RoutesPath.CREDIT_CARD_ERROR,
      RoutesPath.PIX_ERROR,
      RoutesPath.BANK_SLIP_ERROR,
      RoutesPath.EXPIRED_PAYMENT,
      RoutesPath.PRE_AUTHORIZATION_ERROR,
    ],
    components: {
      step1: <CheckedStep />,
      step2: <CurrentErrorStep />,
    },
  },
  {
    paths: [RoutesPath.CREDIT_CARD],
    components: {
      step1: <CurrentCreditCardStep />,
      step2: <FlagStep />,
    },
  },
  {
    paths: [RoutesPath.PIX, RoutesPath.BOLEPIX],
    components: {
      step1: <CurrentPixStep />,
      step2: <FlagStep />,
    },
  },
  {
    paths: [RoutesPath.BANK_SLIP],
    components: {
      step1: <CurrentBankSlipStep />,
      step2: <FlagStep />,
    },
  },
  {
    paths: [
      RoutesPath.OPEN_FINANCE_REVIEW,
      RoutesPath.OPEN_FINANCE_AUTHORIZATION,
    ],
    components: {
      step1: <CheckedStep />,
      step2: <CurrentOpenFinanceStep />,
      step3: <FlagStep />,
    },
  },

  {
    paths: [RoutesPath.OPEN_FINANCE],
    components: {
      step1: <CurrentBankStep />,
      step2: <NextOpenFinanceStep />,
      step3: <FlagStep />,
    },
  },
  {
    paths: [RoutesPath.DIRECT_DEBIT_AUTHORIZATION],
    components: {
      step1: <CheckedStep />,
      step2: <CurrentEditStep />,
      step3: <FlagStep />,
    },
  },
  {
    paths: [RoutesPath.DIRECT_DEBIT],
    components: {
      step1: <CurrentBankStep />,
      step2: <NextEditStep />,
      step3: <FlagStep />,
    },
  },

  {
    paths: [RoutesPath.PRE_AUTHORIZATION_WAITING],
    components: {
      step1: <CheckedStep />,
      step2: <FlagStep />,
    },
  },
];

const renderTimeline = (path: string) => {
  const component = pathComponentMap.find(({ paths }) =>
    paths.some((subPath) => path.includes(subPath)),
  );
  return component ? component : null;
};

const PaymentTimeline: React.FC = () => {
  const location = useLocation();
  const path = location.pathname;

  const pathComponentBuilder = renderTimeline(path);
  return (
    <LayoutCardStep>
      {pathComponentBuilder?.components?.step1}
      <Divider />
      {pathComponentBuilder?.components?.step2}
      {pathComponentBuilder?.components?.step3 && (
        <>
          <Divider />
          {pathComponentBuilder?.components?.step3}
        </>
      )}
    </LayoutCardStep>
  );
};

export default PaymentTimeline;
