import React from "react";
import { Info } from "src/constants/Icons";

interface InfoCardProps {
  message: string;
  className?: string;
}

export const InfoCard: React.FC<InfoCardProps> = ({
  message,
  className = "",
}) => {
  return (
    <div
      className={`flex items-start gap-4 rounded-lg bg-color-neutral-base-2 p-4 text-color-neutral-base-5 ${className}`}
    >
      <Info className="my-auto size-5 flex-shrink-0 text-color-neutral-base-5" />
      <p className="">{message}</p>
    </div>
  );
};
