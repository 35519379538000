import React from "react";

const LayoutCard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <section className="flex w-full items-start justify-center gap-4 lg:max-w-[676px]">
      <div className="flex h-auto w-full flex-col justify-center gap-3 rounded-lg border border-black/0 bg-color-neutral-soft px-4 py-8 shadow md:gap-8 lg:p-8">
        {children}
      </div>
    </section>
  );
};

export default React.memo(LayoutCard);
