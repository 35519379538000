import { Icons, RoutesPath, Strings } from ".";
import ApiPaymentMethods from "./apiPaymentMethods";

export const ApiHeaderKeys = {
  ACCEPT_KEY: "accept",
  ACCEPT_VALUE: "application/json, text/plain, */*",
  CONTENT_TYPE_KEY: "Content-Type",
  CONTENT_TYPE_VALUE: "application/json",
};

export const PaymentMethodKeys = {
  [ApiPaymentMethods.CREDIT_CARD]: {
    type: "CREDIT_CARD",
    icon: Icons.CreditCard,
    label: Strings.CREDIT_CARD,
    description: Strings.EMPTY,
    route: RoutesPath.CREDIT_CARD,
  },
  [ApiPaymentMethods.PIX]: {
    type: "PIX",
    icon: Icons.Pix,
    label: Strings.PIX,
    description: Strings.EMPTY,
    route: RoutesPath.PIX,
  },
  [ApiPaymentMethods.BANK_SLIP]: {
    type: "BANK_SLIP",
    icon: Icons.Barcode,
    label: Strings.BANK_SLIP,
    description: Strings.EMPTY,
    route: RoutesPath.BANK_SLIP,
  },
  [ApiPaymentMethods.OPEN_FINANCE]: {
    type: "OPEN_FINANCE",
    icon: Icons.CreditCard,
    label: Strings.OPEN_FINANCE,
    description: Strings.EMPTY,
    route: RoutesPath.OPEN_FINANCE,
  },
  [ApiPaymentMethods.DIRECT_DEBIT]: {
    type: "DIRECT_DEBIT",
    icon: Icons.BankIcon,
    label: Strings.DIRECT_DEBIT,
    description: Strings.EMPTY,
    route: RoutesPath.DIRECT_DEBIT,
  },
  [ApiPaymentMethods.BOLEPIX]: {
    type: "BOLEPIX",
    icon: Icons.Pix,
    label: Strings.BOLEPIX,
    description: Strings.EMPTY,
    route: RoutesPath.BOLEPIX,
  },
};

export const CreditCardKeys = {
  CARD_NUMBER: "cardNumber",
  HOLDER: "nameOnCard",
  EXPIRATION_DATE: "expirationDate",
  SECURITY_CODE: "cardKey",
  DOCUMENT: "document",
  DATE_OF_BIRTH: "dateOfBirth",
  INSTALLMENTS: "installments",
};
