import JSEncrypt from "jsencrypt";
import { cpf } from "cpf-cnpj-validator";

import { KeyValues, Strings } from "../constants";
import { debugLog } from "./debugLog";
const { CreditCardKeys } = KeyValues;

export const onlyNumbers = (value: string): string => {
  return value.replace(/\D/g, Strings.EMPTY);
};

const cardNumberMask = (value: string) => {
  return (
    value
      .replace(/\D/g, Strings.EMPTY)
      .replace(/(\d{4})(\d)/, "$1 $2")
      .replace(/(\d{4})(\d)/, "$1 $2")
      .replace(/(\d{4})(\d)/, "$1 $2")
      .replace(/(\d{4})(\d)/, "$1 $2")
      .substring(0, 19) ?? Strings.EMPTY
  );
};

const documentMask = (value: string) => {
  return (
    value
      .replace(/\D/g, Strings.EMPTY)
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1-$2")
      .substring(0, 14) ?? Strings.EMPTY
  );
};

const expirationDateMask = (value: string) => {
  return (
    value
      .replace(/\D/g, Strings.EMPTY)
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1/$2")
      .substring(0, 7) ?? Strings.EMPTY
  );
};

const dateOfBirthMask = (value: string) => {
  return (
    value
      .replace(/\D/g, Strings.EMPTY)
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{4})/, "$1")
      .substring(0, 10) ?? Strings.EMPTY
  );
};

const cvvMask = (value: string) => {
  return value.replace(/\D/g, Strings.EMPTY).substring(0, 4);
};

export const mask = (value: string, name: string) => {
  switch (name) {
    case CreditCardKeys.CARD_NUMBER:
      return cardNumberMask(value);
    case CreditCardKeys.DOCUMENT:
      return documentMask(value);
    case CreditCardKeys.EXPIRATION_DATE:
      return expirationDateMask(value);
    case CreditCardKeys.DATE_OF_BIRTH:
      return dateOfBirthMask(value);
    case CreditCardKeys.SECURITY_CODE:
      return cvvMask(value);
    default:
      return value;
  }
};

export const getBinNumber = (value: string) => value.substring(0, 6);

export const validateCardNumber = (value: string) => {
  value = value.replace(/\D/g, Strings.EMPTY);

  let sum = 0;
  let shouldDouble = false;

  for (let i = value.length - 1; i >= 0; i--) {
    let digit = parseInt(value[i]);

    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }

  return sum % 10 === 0;
};

export const validateDocument = (value: string) => {
  const currentValue = onlyNumbers(value);
  return cpf.isValid(currentValue);
};

export const getExpirationMonth = (value: string) => {
  return value.toString().substring(0, 2).padStart(2, "0");
};

export const getExpirationYear = (value: string) => {
  const newValue = value.toString().substring(3, value.length);

  if (newValue.length == 4) {
    return newValue;
  }

  return `20${newValue}`;
};

export const validateExpirationDate = (value: string) => {
  const currentYear = new Date().getFullYear();
  const month = parseInt(getExpirationMonth(value));
  const year = parseInt(getExpirationYear(value));

  const isValidMonth = month >= 1 && month <= 12;
  const isValidYear = year >= currentYear;

  if (!isValidMonth) return false;
  if (!isValidYear) return false;

  return true;
};

export const isOver18 = (value: string) => {
  try {
    const today = new Date();
    const [day, month, year] = value.split("/").map(Number);
    const birthDate = new Date(year, month - 1, day);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age >= 18;
  } catch (error) {
    debugLog({ "DEBUG - isOver18 error:": error });
    return false;
  }
};

export const validateDateOfBirth = (value: string) => {
  const currentYear = new Date().getFullYear();
  const month = parseInt(getExpirationMonth(value));
  const year = parseInt(getExpirationYear(value));

  const isValidMonth = month >= 1 && month <= 12;
  const isValidYear = year >= currentYear;

  if (!isValidMonth) return false;
  if (!isValidYear) return false;

  return true;
};

export const validateHolder = (value: string) => {
  const regex = /^[a-zA-ZÀ-ÖØ-öø-ÿ]{3,}\s[a-zA-ZÀ-ÖØ-öø-ÿ]{1,}.*$/;
  return regex.test(value);
};

export const validateSecurityCode = (value: string) => {
  return value.length >= 3;
};

export const encrypt = (data: CreditCardDataType, publicKey: string) => {
  try {
    const jse = new JSEncrypt();
    jse.setPublicKey(publicKey);
    const encrypted = jse.encrypt(JSON.stringify(data));
    return encrypted;
  } catch {
    throw "Erro ao criptografar dados";
  }
};

export const decodeBase64 = (base64String: string): string => {
  return atob(base64String);
};

export const isValidPEM = (key: string) => {
  const pemFormat =
    /^-----BEGIN PUBLIC KEY-----([A-Za-z0-9+/=]{1,64})*-----END PUBLIC KEY-----$/;
  return pemFormat.test(key);
};

export const getCreditCardBrand = (value: string) => {
  const cardNumber = value.replace(/\s+/g, Strings.EMPTY);

  const visa = /^4\d{12}(\d{3})?$/;
  const mastercard =
    /^(5[1-5]\d{14}|2(22[1-9]\d{12}|2[3-9]\d{13}|[3-6]\d{14}|7[0-1]\d{13}|720\d{12}))$/;
  const amex = /^3[47]\d{13}$/;
  const discover =
    /^(6011\d{12}|65\d{14}|64[4-9]\d{13}|622(12[6-9]|1[3-9]\d|[2-8]\d{2}|9[0-1]\d|92[0-5])\d{10})$/;
  const diners = /^3(0[0-5]|[68]\d)\d{11}$/;

  if (visa.test(cardNumber)) return Strings.VISA;
  if (mastercard.test(cardNumber)) return Strings.MASTERCARD;
  if (amex.test(cardNumber)) return Strings.AMEX;
  if (discover.test(cardNumber)) return Strings.DISCOVER;
  if (diners.test(cardNumber)) return Strings.DINERS;

  return "UNKNOWN";
};
