import React from "react";
import { Strings } from "src/constants";
import { Info } from "src/constants/Icons";
import LayoutCard from "src/components/shared/LayoutCard";
import PaymentTimeline from "src/components/shared/PaymentTimeline";
import { useCheckoutDataContext } from "src/providers/SharedDataProvider";
import { PaymentSummaryCard } from "src/components";
import useNavigateCheckout from "src/hooks/useNavigateCheckout";
import useRefresh from "src/hooks/useRefresh";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import MainLayout from "src/components/shared/MainLayout";
const REFRESH_INTERVAL = 15000; // 15 seconds

const PreAuthorizationWaiting: React.FC = () => {
  const paymentInfo = useCheckoutDataContext();
  const queryClient = useQueryClient();
  useNavigateCheckout(paymentInfo);

  useRefresh({
    interval: REFRESH_INTERVAL,
    onRefresh: () => {
      toast.info("Verificando pagamento...");
      queryClient.invalidateQueries({
        queryKey: ["checkout"],
      });
    },
  });
  return (
    <MainLayout>
      <div className="grid max-w-screen-xl items-start gap-4 rounded-lg p-4 md:grid-flow-col md:grid-cols-[auto_1fr] md:grid-rows-[auto_1fr] lg:px-6">
        <PaymentTimeline />
        <LayoutCard>
          <div className="flex flex-col items-start justify-center gap-2">
            <div className="text-color-neutral-strong text-2xl font-semibold leading-[20px]">
              {Strings.CREDIT_CARD_PAYMENT_PENDING_TITLE}
            </div>
            <p className="items-start">
              {Strings.CREDIT_CARD_PAYMENT_PENDING_SUBTITLE}
            </p>
          </div>
          <div className="grid grid-cols-[auto_1fr] items-center gap-x-4 rounded-lg bg-color-status-info-base p-4">
            <Info className="row-span-2 text-color-status-info-text" />
            <p className="items-start font-bold text-color-status-info-text">
              {Strings.CREDIT_CARD_PAYMENT_PENDING_MESSAGE}
            </p>
          </div>
        </LayoutCard>
        <div className="md:row-span-2">
          <PaymentSummaryCard order={paymentInfo} />
        </div>
      </div>
    </MainLayout>
  );
};

export default PreAuthorizationWaiting;
