interface PaymentMethodOptionProps {
  method: string;
  icon: string;
  label: string;
  description?: string;
  isSelected: boolean;
  backgroundColor: string;
  onSelect: (method: AllowedPaymentMethodsType) => void;
}

export const PaymentMethodOption: React.FC<PaymentMethodOptionProps> = ({
  method,
  icon,
  label,
  description,
  isSelected,
  backgroundColor,
  onSelect,
}) => (
  <label
    className="flex cursor-pointer items-center justify-between rounded-lg border border-color-neutral-base-2 p-4"
    htmlFor={`payment-method-${method}`}
  >
    <div className="flex items-center gap-4">
      <img
        src={icon}
        alt={`${label} payment method`}
        className="h-6 w-6"
        loading="lazy"
      />
      <div className="flex flex-col">
        <span className="text-base">{label}</span>
        {description && (
          <span className="text-sm text-color-neutral-base-4">
            {description}
          </span>
        )}
      </div>
    </div>
    <input
      type="radio"
      id={`payment-method-${method}`}
      name="paymentMethod"
      value={method}
      checked={isSelected}
      onChange={() => onSelect(method as AllowedPaymentMethodsType)}
      className="relative h-5 w-5 cursor-pointer appearance-none rounded border border-gray-300 checked:border-transparent checked:bg-black checked:before:absolute checked:before:inset-0 checked:before:flex checked:before:items-center checked:before:justify-center checked:before:text-white checked:before:content-['✔']"
      style={{
        backgroundColor: isSelected ? backgroundColor : undefined,
      }}
    />
  </label>
);
