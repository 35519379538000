import mock from "src/mock/mock_payment_instruments.json";

export const mockData = (
  data: CheckoutType | undefined,
  { isUseMockData }: { isUseMockData: boolean },
) => {
  if (isUseMockData) {
    return mock as CheckoutType;
  }
  return data;
};
