import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ResponseStatus,
  confirmedPaymentRoutes,
  errorPaymentRoutes,
  scheduledPaymentRoutes,
  waitingPaymentRoutes,
  errorRoutes,
  RoutesPath,
  ApiPaymentMethods,
} from "src/constants";

interface RouteValidation {
  route: boolean;
  paymentMethod: boolean;
}
interface ConfirmedEventInfo {
  timestamp: string;
  found: boolean;
}

const getPaymentMethodValidations = (
  pathname: string,
  token: string,
  data: CheckoutType,
): Record<string, RouteValidation> => {
  const paymentMethod = data.paymentInstruments?.[0]?.method || "";

  return {
    bankSlip: {
      route: pathname === `/${token}${RoutesPath.BANK_SLIP}`,
      paymentMethod: paymentMethod === ApiPaymentMethods.BANK_SLIP,
    },
    pix: {
      route: pathname === `/${token}${RoutesPath.PIX}`,
      paymentMethod: paymentMethod === ApiPaymentMethods.PIX,
    },
  };
};

const getConfirmedEventInfo = (events: EventsType[]): ConfirmedEventInfo => {
  const confirmedEvent = events?.find(
    (event) => event.status === ResponseStatus.CONFIRMED,
  );

  const result = {
    timestamp: confirmedEvent?.updatedAt || "",
    found: !!confirmedEvent?.updatedAt,
  };

  return result;
};

const verifyPaymentConfirmed = (
  status: boolean,
  confirmedEventInfo: ConfirmedEventInfo,
) => {
  if (!status || !confirmedEventInfo.found) {
    return false;
  }

  const currentTimestamp = new Date().getTime();
  const confirmedTimestamp = new Date(confirmedEventInfo.timestamp).getTime();
  const timeDifference = currentTimestamp - confirmedTimestamp;
  const TEN_SECONDS = 10000;

  return timeDifference >= TEN_SECONDS;
};

const NavigationHandlers = {
  handleInvalidPaymentAccess: (
    validations: Record<string, RouteValidation>,
    navigate: (path: string) => void,
    token: string,
  ) => {
    const hasInvalidAccess = Object.values(validations).some(
      ({ route, paymentMethod }) => route && !paymentMethod,
    );

    if (hasInvalidAccess) {
      navigate(`/${token}${RoutesPath.PAYMENT_METHODS}`);
      return true;
    }
    return false;
  },
};

const getStatusCheck = (
  data: CheckoutType,
  pathname: string,
  token: string,
) => {
  const paymentMethod = data.paymentInstruments?.[0]?.method || "";

  const status = data.status;
  const isPaymentConfirmed = status === ResponseStatus.CONFIRMED;
  const confirmedEventInfo = getConfirmedEventInfo(data.events);

  return {
    paymentMethod,
    status,
    isPaymentScheduled: data.isPaymentScheduled,
    isBankSlipPaymentMethod: paymentMethod === ApiPaymentMethods.BANK_SLIP,
    isPixPaymentMethod: paymentMethod === ApiPaymentMethods.PIX,
    isBankSlipRoute: pathname === `/${token}${RoutesPath.BANK_SLIP}`,
    isPixRoute: pathname === `/${token}${RoutesPath.PIX}`,
    isPendingStatus: status === ResponseStatus.PENDING,
    isConfirmedStatusAndPaymentMethodRoute:
      status === ResponseStatus.CONFIRMED &&
      paymentMethod in confirmedPaymentRoutes,
    isPreAuthorizationErrorWithoutPaymentMethod:
      status === ResponseStatus.PRE_AUTHORIZATION_ERROR && !paymentMethod,
    isPaymentMethodSelectionRoute:
      pathname === `/${token}${RoutesPath.PAYMENT_METHODS}`,
    isWaitingPaymentRoute: status in waitingPaymentRoutes,
    isPreAuthorizationErrorStatus:
      status === ResponseStatus.PRE_AUTHORIZATION_ERROR,
    isPaymentConfirmedLongTimeAgo: verifyPaymentConfirmed(
      isPaymentConfirmed,
      confirmedEventInfo,
    ),
    isFutureStatus: status === ResponseStatus.FUTURE,
  };
};

const useNavigateCheckout = (data: CheckoutType) => {
  const params = useParams<OrderSubscriptionParamsType>();

  const token = params.token as string;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!data) {
      return;
    }

    const {
      paymentMethod,
      status,
      isPaymentScheduled,
      isPendingStatus,
      isConfirmedStatusAndPaymentMethodRoute,
      isPreAuthorizationErrorWithoutPaymentMethod,
      isPaymentMethodSelectionRoute,
      isWaitingPaymentRoute,
      isPreAuthorizationErrorStatus,
      isPaymentConfirmedLongTimeAgo,
      isFutureStatus,
    } = getStatusCheck(data, pathname, token);

    const paymentMethodValidations = getPaymentMethodValidations(
      pathname,
      token,
      data,
    );

    if (isFutureStatus) {
      if (!isPaymentScheduled) {
        NavigationHandlers.handleInvalidPaymentAccess(
          paymentMethodValidations,
          navigate,
          token,
        );
        return;
      }

      navigate(`/${token}${RoutesPath.PAYMENT_SCHEDULED_SUCCESS}`);
      return;
    }

    if (isPendingStatus) {
      NavigationHandlers.handleInvalidPaymentAccess(
        paymentMethodValidations,
        navigate,
        token,
      );
      return;
    }

    if (isPaymentMethodSelectionRoute && isPreAuthorizationErrorStatus) {
      return;
    }

    if (isPaymentConfirmedLongTimeAgo) {
      navigate(`/${token}${RoutesPath.PAYMENT_CONFIRMED}`);
      return;
    }

    if (isPreAuthorizationErrorWithoutPaymentMethod) {
      navigate(`/${token}${errorRoutes[status]}`);
      return;
    }

    if (isWaitingPaymentRoute) {
      navigate(`/${token}${waitingPaymentRoutes[status]}`);
      return;
    }

    if (isConfirmedStatusAndPaymentMethodRoute) {
      navigate(`/${token}${confirmedPaymentRoutes[paymentMethod]}`);
      return;
    }

    if (status in scheduledPaymentRoutes && isPaymentScheduled) {
      navigate(`/${token}${scheduledPaymentRoutes[status]}`);
      return;
    }

    if (paymentMethod in errorPaymentRoutes) {
      navigate(`/${token}${errorPaymentRoutes[paymentMethod][status]}`);
      return;
    }

    navigate(`${RoutesPath.HOME}`);
  }, [data, navigate, token, pathname]);
};

export default useNavigateCheckout;
