import { api } from "src/api";

export interface RequestInputType {
  token: string;
  request: ResquestType; // Assuming `ResquestType` is your request payload
}

export const checkout = async (token: string): Promise<CheckoutType> => {
  const url = `/checkout/${token}`;
  const { data } = await api.get(url);
  return data;
};

export const tokenizer = async (
  token: string,
  request: TokenizerRequestType,
): Promise<TokenizerResponseType> => {
  const url = `/checkout/${token}/tokenizer_card`;
  const { data } = await api.post(url, request);
  return data;
};

export const pay = async (
  token: string,
  request: CreditCardResquestType,
): Promise<CreditCardCheckoutResponse> => {
  const url = `/checkout/${token}/pay`;
  const { data } = await api.post(url, request);
  return data;
};

export const payOrderCheckout = async ({
  token,
  request,
}: RequestInputType): Promise<
  PixCheckoutResponse | BankSlipCheckoutResponse
> => {
  const url = `/checkout/${token}/pay`;
  const { data } = await api.post(url, request);
  if (request.paymentInstruments[0].type === "PIX") {
    return data as PixCheckoutResponse;
  }

  if (request.paymentInstruments[0].type === "BANK_SLIP") {
    return data as BankSlipCheckoutResponse;
  }
  return data;
};
export const pixPayment = async (
  token: string,
  request: ResquestType,
): Promise<PixCheckoutResponse> => {
  const url = `/checkout/${token}/pay`;
  const { data } = await api.post(url, request);
  return data;
};

export const bankSlipPayment = async (
  token: string,
  request: ResquestType,
): Promise<BankSlipCheckoutResponse> => {
  const url = `/checkout/${token}/pay`;
  const { data } = await api.post(url, request);
  return data;
};
export const directDebitPaymentBankList = async (
  token: string | undefined,
): Promise<DirectDebitBankListResponse> => {
  const url = `/checkout/${token}/directdebit/banks`;
  const { data } = await api.get(url);
  return data;
};

export const createDirectDebit = async (
  token: string | undefined,
  request: DirectDebitData,
): Promise<DirectDebitAuthorizationResponse> => {
  const url = `/checkout/${token}/directdebit`;
  const { data } = await api.post(url, request);
  return data;
};

export const directDebitPay = async (
  token: string | undefined,
  request: ResquestType,
): Promise<CheckoutType> => {
  const url = `/checkout/${token}/pay`;
  const { data } = await api.post(url, request);
  return data;
};
