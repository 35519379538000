import React from "react";

import { KeyValues, DEFAULT_COLORS } from "src/constants";
import { useCheckoutDataContext } from "src/providers/SharedDataProvider";
import { PaymentMethodOption } from "../shared/PaymentMethodOption";

type SelectPaymentMethodsProps = {
  data: AllowedPaymentMethodsType[];
  selectedMethod: AllowedPaymentMethodsType;
  handlePaymentMethodChange: (method: AllowedPaymentMethodsType) => void;
};

const SelectPaymentMethods: React.FC<SelectPaymentMethodsProps> = ({
  data,
  selectedMethod,
  handlePaymentMethodChange,
}) => {
  const { PaymentMethodKeys } = KeyValues;
  const order = useCheckoutDataContext();
  const backgroundColor =
    order?.visualPreferences?.layout?.colors?.buttonConfirmBackground ||
    DEFAULT_COLORS.buttonConfirmBackground;

  return (
    <fieldset className="flex flex-col gap-4 rounded-lg">
      <legend className="sr-only">Select payment method</legend>

      <div role="radiogroup" className="flex flex-col gap-4">
        {data.map((method) => {
          const paymentMethodData = PaymentMethodKeys[method];

          if (!paymentMethodData) return null;

          return (
            <PaymentMethodOption
              key={method}
              method={method}
              icon={paymentMethodData.icon}
              label={paymentMethodData.label}
              description={paymentMethodData.description}
              isSelected={selectedMethod === method}
              backgroundColor={backgroundColor}
              onSelect={() => handlePaymentMethodChange(method)}
            />
          );
        })}
      </div>
    </fieldset>
  );
};

export default SelectPaymentMethods;
