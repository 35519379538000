import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";

import { HttpStatusCodes } from "src/constants";
import { debugInfo } from "./debugLog";

class AxiosHelper {
  readonly validateStatus = (status: number) => {
    return (
      status >= HttpStatusCodes.SUCCESS &&
      status < HttpStatusCodes.MULTIPLE_CHOICES
    );
  };

  readonly handleError = (error: AxiosError) => {
    if (error.response) {
      const data = error?.response?.data as ErrorResponse;
      if (Array.isArray(data.cause.errors)) {
        return {
          statusCode: data.responseCode,
          cause: data.cause?.errors?.map((error) => error.message).join(", "),
          requestId: data.requestId,
        };
      }
      return {
        statusCode: data.responseCode,
        cause: data.cause,
        requestId: data.requestId,
      };
    }
    if (error.request) {
      return {
        statusCode: 400,
        cause: {
          errors: [
            {
              error: "NO_INTERNET_CONNECTION",
              message: "Favor verifique sua internet.",
            },
          ],
        },
      };
    }

    return {
      statusCode: error.code,
      cause: {
        errors: [
          {
            error: error.code,
            message: error.cause,
          },
        ],
      },
    };
  };

  readonly interceptorRequest = async (request: InternalAxiosRequestConfig) => {
    const { baseURL, data, method, url } = request;
    debugInfo(`-> [${method}] - ${baseURL}${url}`);

    debugInfo(`headers: ${JSON.stringify(request.headers, null, 2)}`);
    if (data) {
      debugInfo({ "BODY:": data });
    }

    return request;
  };

  readonly interceptorResponse = async (response: AxiosResponse) => {
    const {
      status,
      config: { baseURL, url, method },
    } = response;

    const data = response.data;

    debugInfo(
      `<- [${method}] - ${baseURL}${url} - [${status}|${data?.returnCode}]`,
    );
    if (data) {
      debugInfo({ "BODY:": data });
    }

    return response;
  };
}

const ApiHelper = new AxiosHelper();
export default ApiHelper;
