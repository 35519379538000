import React from "react";
import ButtonSpinner from "../ButtonSpinner";
import { useCheckoutDataContext } from "src/providers/SharedDataProvider";
import { DEFAULT_COLORS } from "src/constants";
type ButtonProps = {
  text: string;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  isLoading?: boolean;
  position?: "left" | "right";
  variant?: "primary" | "secondary";
  className?: string;
  size?: "small" | "medium" | "large";
  onClick: () => void;
};

const SecondaryButton: React.FC<ButtonProps> = ({
  text,
  Icon,
  position = "right",
  isLoading = false,
  className = "",
  variant = "secondary",
  size = "medium",
  onClick,
}) => {
  const data = useCheckoutDataContext();
  const buttonConfirmBackground =
    data.visualPreferences.layout.colors.buttonConfirmBackground ||
    DEFAULT_COLORS.buttonConfirmBackground;
  return (
    <button
      className={`inline-flex w-fit items-center gap-2 rounded-full px-5 py-2 text-base font-normal leading-normal text-color-neutral-soft ${variant === "primary" ? "bg-color-primary-base text-color-neutral-soft" : "border border-color-neutral-base-3 bg-color-neutral-soft text-color-primary-base"} text-base font-normal leading-normal ${className} hover:bg-color-neutral-base-2`}
      onClick={onClick}
      style={{
        color: buttonConfirmBackground,
      }}
    >
      {isLoading ? (
        <div className="relative w-1/2">
          <ButtonSpinner width="24px" height="24px" />
        </div>
      ) : (
        <div
          className={`inline-flex items-center gap-2 ${position === "left" ? "flex-row-reverse" : "flex-row"}`}
        >
          <span>{text}</span>
          {Icon && (
            <Icon
              className={`size-${size}`}
              style={{ color: buttonConfirmBackground }}
            />
          )}
        </div>
      )}
    </button>
  );
};

export default SecondaryButton;
