const RoutesPath = {
  HOME: "/",
  PIX: "/pix",
  PIX_SUCCESS: "/pix/success",
  PIX_ERROR: "/pix/error",
  CREDIT_CARD: "/credit-card",
  CREDIT_CARD_SUCCESS: "/credit-card/success",
  CREDIT_CARD_SCHEDULED: "/credit-card/scheduled",
  CREDIT_CARD_ERROR: "/credit-card/error",
  CREDIT_CARD_PENDING: "/credit-card/pending",
  PRE_AUTHORIZATION_WAITING: "/pre-authorization-waiting",
  BANK_SLIP: "/bankslip",
  BANK_SLIP_SUCCESS: "/bankslip/success",
  BANK_SLIP_WAITING_PAYMENT: "/bankslip/waiting-payment",
  BANK_SLIP_ERROR: "/bankslip/error",
  PAYMENT_METHODS: "/payment-methods",
  OPEN_FINANCE: "/open-finance",
  OPEN_FINANCE_REVIEW: "/open-finance/review",
  OPEN_FINANCE_AUTHORIZATION: "/open-finance/authorization",
  OPEN_FINANCE_SUCCESS: "/open-finance/success",
  OPEN_FINANCE_TIMEOUT: "/open-finance/timeout",
  EXPIRED_PAYMENT: "/expired-payment",
  PRE_AUTHORIZATION_ERROR: "/pre-authorization-error",
  PAYMENT_CONFIRMED: "/payment-confirmed",
  DIRECT_DEBIT: "/direct-debit",
  DIRECT_DEBIT_AUTHORIZATION: "/direct-debit/authorization",
  DIRECT_DEBIT_SUCCESS: "/direct-debit/success",
  DIRECT_DEBIT_ERROR: "/direct-debit/error",
  PAYMENT_SCHEDULED_SUCCESS: "/payment-scheduled/success",
  BOLEPIX: "/bolepix",
  BOLEPIX_SUCCESS: "/bolepix/success",
  BOLEPIX_ERROR: "/bolepix/error",
};

export default RoutesPath;
